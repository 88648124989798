import React from 'react';
import PropTypes from 'prop-types';
import {
	Container, Col, Row, CardHeader, Card, CardBody, CardFooter, Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		if (error?.name === 'ChunkLoadError' || error?.code === 'CSS_CHUNK_LOAD_FAILED') {
			throw error;
		}
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		global.rollbar.error(`Rollbar Error caught by ErrorBoundary: ${error} ${errorInfo}`, { err: error, errInfo: errorInfo });
	}

	render() {
		const { hasError } = this.state;
		const { children, history } = this.props;
		if (hasError) {
			return (
				<Container style={{ height: '100vh' }} className="d-flex align-items-center justify-content-center">
					<Row>
						<Col>
							<Card>
								<CardHeader>Une erreur est survenue !</CardHeader>
								<CardBody>
									L&apos;équipe de développement vient d&apos;être informée.
									<br />
									Cliquez sur le bouton ci-dessous pour revenir sur l&apos;application
								</CardBody>
								<CardFooter className="d-flex align-items-center justify-content-end">
									<Button onClick={() => {
										this.setState({ hasError: false });
										history.push('/');
									}}
									>
										Retour
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>

				</Container>
			);
		}
		return children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

const withRouterErrorBoundary = withRouter(ErrorBoundary);
export { withRouterErrorBoundary as ErrorBoundary };
