import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';

export const IS_ENABLE_EXT_MANAGER = process.env.REACT_APP_ENABLE_EXT_MANAGER === 'true';

const SuperAdminCreateAdmin = lazy(() => import('./screens/superAdmin/CreateAdmin'));
const SuperAdminCreateManager = lazy(() => import('./screens/superAdmin/CreateManager'));

const SuperAdminMission = lazy(() => import('./screens/admin/Mission'));

const SuperAdminExport = lazy(() => import('./screens/superAdmin/Export'));

const ParticipantMission = lazy(() => import('./screens/participant/ParticipantMission'));
const ParticipantCreate = lazy(() => import('./screens/participant/ParticipantCreate'));

const AccountList = lazy(() => import('./screens/account/AccountList'));
const AccountAnimatorEdit = lazy(() => import('./screens/account/AccountAnimatorEdit'));
const AccountAdminEdit = lazy(() => import('./screens/account/AccountAdminEdit'));
const AccountManagerEdit = lazy(() => import('./screens/account/AccountManagerEdit'));

const AdminCalendar = lazy(() => import('./screens/admin/Calendar'));
const AdminAnimatorCreate = lazy(() => import('./screens/admin/AnimatorCreate'));
const AdminAnimatorList = lazy(() => import('./screens/admin/FavoriteAnimatorsList'));
const AdminHome = lazy(() => import('./screens/admin/Mission'));
const AdminIntervention = lazy(() => import('./screens/admin/Intervention'));
const AdminInterventionEditAssign = lazy(() => import('./screens/admin/InterventionEditAssign'));
const AdminInterventionEdit = lazy(() => import('./screens/admin/InterventionEdit'));
const AdminInterventionRead = lazy(() => import('./screens/admin/InterventionRead'));
// const AdminIntervention = lazy(() => import('./screens/admin/Intervention'));
// const AdminMissionRead = lazy(() => import('./screens/mission/Read'));
const AdminMissionCreate = lazy(() => import('./screens/mission/Create'));
const AdminProfile = lazy(() => import('./screens/admin/Profile'));

const ManagerHome = lazy(() => import('./screens/Manager/Home'));
const ManagerProfile = lazy(() => import('./screens/Manager/Profile'));
const ManagerInterventionCreate = lazy(() => import('./screens/Manager/InterventionCreate'));
const ManagerInterventionEdit = lazy(() => import('./screens/Manager/InterventionEdit'));

const ManagerInterventionRead = lazy(() => import('./screens/Manager/InterventionRead'));

const AnimatorCalendar = lazy(() => import('./screens/animator/Calendar'));
const AnimatorIntervention = lazy(() => import('./screens/animator/Intervention'));
const AnimatorInterventionRead = lazy(() => import('./screens/animator/InterventionRead'));
const AnimatorHome = lazy(() => import('./screens/animator/Home'));
const AnimatorMission = lazy(() => import('./screens/animator/Mission'));
const AnimatorProfile = lazy(() => import('./screens/animator/Profile'));
const MissionCreate = lazy(() => import('./screens/mission/Create'));
const MissionEdit = lazy(() => import('./screens/mission/Edit'));
const MissionRead = lazy(() => import('./screens/mission/Read'));

const role = {
	admin: process.env.REACT_APP_AWS_COGNITO_GROUP_ADMIN,
	animator: process.env.REACT_APP_AWS_COGNITO_GROUP_ANIMATOR,
	superAdmin: process.env.REACT_APP_AWS_COGNITO_GROUP_SUPERADMIN,
	manager: process.env.REACT_APP_AWS_COGNITO_GROUP_MANAGER,
};

const Loading = <div className="animated fadeIn pt-3 text-center">Chargement...</div>;

// Pages
const DefaultLayout = lazy(() => import('./components/DefaultLayout'));

const PageAuthForgotPassword = lazy(() => import('./screens/Auth/ForgotPassword'));

const PageLogin = lazy(() => import('./screens/Auth/Login'));

const PageFinalize = lazy(() => import('./screens/Auth/Finalize'));

const PageForbidden = lazy(() => import('./screens/Auth/Forbidden'));

export const Routes = () => (
	<Suspense fallback={Loading}>
		<Switch>
			<Route path={[
				'/login',
				'/reset-password',
				'/forgot-password',
				'/finalize/:credentials',
				'/forbidden',
			]}
			>
				<Switch>
					<Route path="/login" name="Login" component={PageLogin} />
					<Route path="/forgot-password" name="Forgot" component={PageAuthForgotPassword} />
					<Route path="/finalize/:credentials" name="Reset" component={PageFinalize} />
					<Route path="/forbidden" component={PageForbidden} />
				</Switch>
			</Route>
			<Route path={[
				'/',
				'/admin',
				'/admin/admin/create',
				'/admin/animator',
				'/admin/animator/:matricule',
				'/admin/animator/create',
				'/admin/calendar',
				// '/admin/export',
				'/admin/intervention',
				'/admin/intervention/:id',
				'/admin/intervention/edit/assign/:id',
				'/admin/intervention/edit/:id',
				'/admin/manager/create',
				'/admin/mission',
				'/admin/mission/:id',
				'/admin/mission/cancel/:id',
				'/admin/mission/closed/:id',
				'/admin/mission/create',
				'/admin/mission/edit/:id',
				'/admin/participant/create',
				'/admin/profile',
				'/admin/user',
				'/admin/user/admin/:matricule',
				'/admin/user/animator/:matricule',
				'/admin/user/manager/:matricule',
				'/calendar',
				'/manager',
				'/manager/intervention/create',
				'/manager/intervention/edit/:id',
				'/manager/intervention/:id/mission/:id',
				'/manager/intervention/:id/mission',
				'/manager/intervention/:id',
				'/manager/mission/:id',
				'/intervention',
				'/intervention/:id',
				'/mission/create/from-intervention/:interventionId',
				'/mission',
				'/mission/:id',
				'/mission/cancel/:id',
				'/mission/closed/:id',
				'/mission/create',
				'/mission/edit/:id',
				'/participant/mission',
				'/profile',
			]}
			>
				<DefaultLayout containerFluidPages={['/calendar', '/admin/calendar']}>
					<Switch>
						{/* ANIMATOR */}
						<PrivateRoute path="/mission/create" exact component={MissionCreate} role={role.animator} />
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/mission/create/from-intervention/:interventionId" exact component={MissionCreate} role={role.animator} /> }
						<PrivateRoute path="/mission/create/from-following-mission/:followingMisionId" exact component={MissionCreate} role={role.animator} />
						<PrivateRoute path="/mission/cancel/:id" key="cancel" exact component={MissionRead} role={role.animator} />
						<PrivateRoute path="/mission/edit/:id" exact component={MissionEdit} role={role.animator} />
						<PrivateRoute path="/mission/:id" key="read" exact component={MissionRead} role={role.animator} />
						<PrivateRoute path="/mission/closed/:id" exact component={MissionRead} role={role.animator} />
						<PrivateRoute path="/mission" exact component={AnimatorMission} role={role.animator} />
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/intervention" exact component={AnimatorIntervention} role={role.animator} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/intervention/:id" exact component={AnimatorInterventionRead} role={role.animator} /> }
						<PrivateRoute path="/participant/mission" component={ParticipantMission} role={role.animator} />
						<PrivateRoute path="/profile" exact component={AnimatorProfile} role={role.animator} />
						<PrivateRoute path="/calendar" exact component={AnimatorCalendar} role={role.animator} />
						<PrivateRoute path="/" exact component={AnimatorHome} role={role.animator} />

						{/* MANAGER */}
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/manager" exact component={ManagerHome} role={role.manager} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/manager/intervention/create" exact component={ManagerInterventionCreate} role={role.manager} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/manager/intervention/edit/:id" exact component={ManagerInterventionEdit} role={role.manager} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/manager/intervention/:id" exact component={ManagerInterventionRead} role={role.manager} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/manager/intervention/cancel/:id" exact component={ManagerInterventionRead} role={role.manager} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/manager/mission/:id" exact component={MissionRead} role={role.manager} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/manager/profile" exact component={ManagerProfile} role={role.manager} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/manager/participant/mission" component={ParticipantMission} role={role.manager} /> }

						{/* ADMIN */}
						<PrivateRoute path="/admin" exact component={AdminHome} role={role.admin} />
						<PrivateRoute path="/admin/profile" exact component={AdminProfile} role={role.admin} />
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/admin/intervention" exact component={AdminIntervention} role={role.admin} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/admin/intervention/:id" exact component={AdminInterventionRead} role={role.admin} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/admin/intervention/cancel/:id" exact component={AdminInterventionRead} role={role.admin} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/admin/intervention/refuse/:id" exact component={AdminInterventionRead} role={role.admin} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/admin/intervention/edit/assign/:id" exact component={AdminInterventionEditAssign} role={role.admin} /> }
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/admin/intervention/edit/:id" exact component={AdminInterventionEdit} role={role.admin} /> }
						<PrivateRoute path="/admin/animator" exact component={AdminAnimatorList} role={role.admin} />
						<PrivateRoute path="/admin/animator/create" exact component={AdminAnimatorCreate} role={role.admin} />
						<PrivateRoute path="/admin/animator/:matricule" exact component={AccountAnimatorEdit} role={role.admin} />
						<PrivateRoute path="/admin/mission/create" exact component={AdminMissionCreate} role={role.admin} />
						<PrivateRoute path="/admin/mission/:id" exact component={MissionRead} role={role.admin} />
						<PrivateRoute path="/admin/mission/edit/:id" exact component={MissionEdit} role={role.admin} />
						<PrivateRoute path="/admin/mission/cancel/:id" exact component={MissionRead} role={role.admin} />
						<PrivateRoute path="/admin/mission/closed/:id" exact component={MissionRead} role={role.admin} />
						<PrivateRoute path="/admin/calendar" exact component={AdminCalendar} role={role.admin} />
						<PrivateRoute path="/admin/participant/mission" exact component={ParticipantMission} role={role.admin} />
						<PrivateRoute path="/admin/mission" exact component={SuperAdminMission} role={role.admin} />

						{/* SUPER_ADMIN */}
						<PrivateRoute path="/admin/export" exact component={SuperAdminExport} role={role.superAdmin} />
						<PrivateRoute path="/admin/admin/create" exact component={SuperAdminCreateAdmin} role={role.superAdmin} />
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/admin/manager/create" exact component={SuperAdminCreateManager} role={role.superAdmin} /> }
						<PrivateRoute path="/admin/participant/create" exact component={ParticipantCreate} role={role.superAdmin} />
						<PrivateRoute path="/admin/user" exact component={AccountList} role={role.superAdmin} />
						<PrivateRoute path="/admin/user/animator/:matricule" exact component={AccountAnimatorEdit} role={role.superAdmin} />
						<PrivateRoute path="/admin/user/admin/:matricule" exact component={AccountAdminEdit} role={role.superAdmin} />
						{ IS_ENABLE_EXT_MANAGER && <PrivateRoute path="/admin/user/manager/:matricule" exact component={AccountManagerEdit} role={role.superAdmin} /> }
					</Switch>
				</DefaultLayout>
			</Route>
		</Switch>
	</Suspense>
);
