import { HashRouter, Switch } from 'react-router-dom';
import {
	useEffect, useState, useCallback, useMemo,
} from 'react';
import { Auth, Hub } from 'aws-amplify';
import {
	QueryClient,
	QueryClientProvider,
} from 'react-query';

import { ChunkLoadError } from '@53js/react-chunkloaderror';
import { UserContext } from './components/UserContext';
import { cognitoGroup } from './lib/cognito/groups';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ScrollToTop } from './components/Scroll/ToTop';
import { Routes } from './Routes';
import './lib/font-awesome';
import './App.scss';

const queryClient = new QueryClient();

/*const DeveloperPageAuthResetPassword = props => <PageAuthResetPassword developer {...props} />;
const DeveloperPageAuthResetPasswordWithCode = props => (
	<DeveloperPageAuthResetPassword withCode {...props} />);
*/
// eslint-disable-next-line react/prefer-stateless-function
const App = () => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [currentUser, setCurrentUser] = useState({});

	const updateCurrentUser = async (user, force = false) => {
		if (user) {
			const userGroups = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
			setCurrentUser({
				...user,
				sub: user.attributes.sub,
				isAdmin: userGroups.includes(cognitoGroup.admin),
				isAnimator: userGroups.includes(cognitoGroup.animator),
				isManager: userGroups.includes(cognitoGroup.manager),
				isSuperAdmin: userGroups.includes(cognitoGroup.superAdmin),
			});

			return;
		}
		try {
			let authUser;
			if (force) {
				authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
			} else {
				authUser = await Auth.currentAuthenticatedUser();
			}
			const userGroups = authUser?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
			setCurrentUser({
				...authUser,
				sub: authUser.attributes.sub,
				isAdmin: userGroups.includes(cognitoGroup.admin),
				isAnimator: userGroups.includes(cognitoGroup.animator),
				isManager: userGroups.includes(cognitoGroup.manager),
				isSuperAdmin: userGroups.includes(cognitoGroup.superAdmin),
			});
			setIsLoaded(true);
		} catch (err) {
			setCurrentUser(null);
			setIsLoaded(true);
		}
	};

	useEffect(() => {
		updateCurrentUser();
		Hub.listen('auth', (data) => {
			if (data.payload.event !== 'signIn') {
				updateCurrentUser();
			}
		});
	}, []);

	const handleErrorRaised = useCallback((error, errorInfo) => {
		global.rollbar.error(`Rollbar Error caught by ChunkLoadError: ${error} ${errorInfo}`);
	}, []);

	const providerUserValue = useMemo(() => ({
		user: currentUser,
		updateCurrentUser,
		isLoaded,
	}), [currentUser, isLoaded]);

	return (
		<QueryClientProvider client={queryClient}>
			<UserContext.Provider value={providerUserValue}>
				<HashRouter>
					<ScrollToTop>
						<ChunkLoadError
							onErrorRaisedCallback={handleErrorRaised}
						>
							<ErrorBoundary>
								<Switch>
									<Routes />
								</Switch>
							</ErrorBoundary>
						</ChunkLoadError>
					</ScrollToTop>
				</HashRouter>
			</UserContext.Provider>
		</QueryClientProvider>
	);
};

export default App;
